export default () => {
    const openers = document.getElementsByClassName("modal-open");
    const closers = document.getElementsByClassName("modal-close");
    for (const opener of openers) {
        opener.addEventListener("click", () => {
            const modal = opener.nextElementSibling;
            modal.classList.remove("hidden");
        });
    }
    for (const closer of closers) {
        closer.addEventListener("click", () => {
            const modal = closer.parentElement.parentElement.parentElement;
            modal.classList.add("hidden");
        });
    }
}