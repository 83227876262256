export default () => {
    if (apos.user !== undefined) {
        setInterval(detectEditMode, 1000)
    }
}

var isEditMode = false

function detectEditMode() {
    if (apos.adminBar.editMode !== isEditMode) {
        isEditMode = apos.adminBar.editMode
        if (apos.adminBar.editMode === true) {
            document.querySelectorAll("[data-apos-refreshable]")[0].classList.add("edit-mode")
        } else {
            document.querySelectorAll("[data-apos-refreshable]")[0].classList.remove("edit-mode")
        }
    }
}