export default () => {
        let menuOpen = document.getElementById("menu-open");
        let menuClose = document.getElementById("menu-close");
        let menuBack = document.getElementById("menu-back");
        let subMenuOpeners = document.getElementsByClassName("submenu-opener");
        if (menuOpen === null) {
                return;
        }
        if (menuClose === null) {
                return;
        }

        menuOpen.addEventListener("click", openMenuOverlay);
        menuClose.addEventListener("click", closeMenuOverlay);
        menuBack.addEventListener("click", closeSubMenu);

        for (let subMenuOpenerIndex = 0; subMenuOpenerIndex < subMenuOpeners.length; subMenuOpenerIndex++) {
                const subMenuOpener = subMenuOpeners[subMenuOpenerIndex];
                subMenuOpener.addEventListener("click", () => openSubMenu(subMenuOpenerIndex + 1));
        }
};

function openMenuOverlay() {
        let menuOverlay = document.getElementById("menu-overlay");
        menuOverlay.classList.remove("hidden");
}

function closeMenuOverlay() {
        let menuOverlay = document.getElementById("menu-overlay");
        menuOverlay.classList.add("hidden");
}

function openSubMenu(index) {
        const menuBack = document.getElementById("menu-back");
        const menuContainer = document.getElementById("menu-container");
        const navigationPages = menuContainer.children[0].children;

        for (const navigationPage of navigationPages) {
                navigationPage.classList.add("hidden");
        }

        navigationPages[index].classList.remove("hidden");
        menuBack.classList.remove("hidden");
}

function closeSubMenu() {
        const navigationPages = menuContainer.children[0].children;

        for (const navigationPage of navigationPages) {
                navigationPage.classList.add("hidden");
        }

        navigationPages[0].classList.remove("hidden");
}
